<template>
	<div
		class="cart-shade"
		:class="{ open: open }"
	>
		<div
			class="outside"
			@click="closeMenu"
			:class="{ open: open }"
		></div>
		<aside
			class="cart-menu"
			:class="{ open: open }"
		>
			<!-- TOPPER -->
			<div class="cart-top">
				<h2 class="hidden-md hidden-lg">
					<i class="fa fa-fw fa-shopping-cart fa-lg"></i> My Cart ({{ getCart.quantity }}
					items)
				</h2>
				<button
					@click="closeMenu"
					class="close-button"
				>
					<i class="fa fa-fw fa-times fa-lg"></i>CLOSE
				</button>
			</div>

			<!-- MINI CART -->
			<div
				class="menu-container"
				:class="{ short: shortScreen, stuck: stuck }"
				v-if="getCart.quantity > 0"
			>
				<div class="cart-list">
					<div
						class="cart-items"
						:class="{ short: shortScreen }"
					>
						<div
							class="cart-item"
							:class="{ 'core-item': parseInt(item.is_core) > 0 }"
							v-for="item in getCart.items"
							:key="item.sku"
						>
							<div
								class="item-thumb"
								v-if="item.image !== null"
							>
								<img
									:src="siteCdn + 'cdn-cgi/image/width=64,quality=70/' + item.image"
									:alt="item.name"
								/>
								<figcaption>
									<p>{{ item.qty }}</p>
								</figcaption>
							</div>
							<div class="item-info">
								<div class="item-name">
									<span v-if="parseInt(item.is_core) > 0">{{ item.name }}</span>
									<a
										v-else
										:href="generatePartUrl(item)"
										>{{ item.name }}</a
									>
									<span class="text-default">{{ item.price_formatted_display }}</span>
								</div>

								<div class="item-data">
									<small v-if="parseInt(item.is_core) > 0">
										<CoreDepositPopover trigger="click">
											<span>Refundable Core Deposit</span>
										</CoreDepositPopover>
									</small>
									<small
										v-else-if="item.stock == 0"
										data-toggle="popover"
										:title="`${shipsInPopoverTitle} Ships in ${getAdditionalMinShippingDays(
											item
										)} to ${getAdditionalMaxShippingDays(item)} business days`"
										:data-content="'Expected in stock ' + getAvailabilityDates(item) + '.'"
										data-placement="bottom"
										data-trigger="click"
										data-html="true"
										data-container="body"
										class="cursor-pointer"
									>
										Ships in {{ getAdditionalMinShippingDays(item) }} to
										{{ getAdditionalMaxShippingDays(item) }} days
									</small>
									<small
										v-else
										data-toggle="popover"
										:title="inStockPopoverTitle"
										data-content="Ships within 24 hours if ordered before 3:00PM EST Monday-Friday."
										data-placement="bottom"
										data-trigger="click"
										data-html="true"
										data-container="body"
										class="in-stock cursor-pointer"
									>
										<i class="glyphicon glyphicon-ok-circle"></i>
										In Stock
									</small>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="cart-bottom">
					<!-- PROGRESS BARS -->
					<div class="shipping-progress freeShoppingProgressContainer">
						<div class="to-go">{{ toGoDisplay }}</div>
						<div class="bar">
							<span>$0</span>
							<div class="progress">
								<div
									class="progress-bar progress-bar-danger"
									role="progressbar"
									:aria-valuenow="getCart.total"
									aria-valuemin="0"
									aria-valuemax="149"
									:style="progressBar"
								></div>
							</div>
							<span>$149</span>
						</div>
					</div>

					<!-- SUBTOTAL -->
					<div class="subtotal">
						<span class="subtotal-label">Subtotal</span>
						<span class="subtotal-value">${{ this.money(getCart.total) }}</span>
					</div>
					<div
						v-if="this.money(getCart.total) > 30.0"
						class="or text-muted"
					>
						<div class="or-line">&nbsp;</div>
						<span>OR</span>
						<div class="or-line">&nbsp;</div>
					</div>
					<!--  paypal messaging -->
					<div
						v-if="this.money(getCart.total) > 30.0"
						data-pp-message
						:data-pp-amount="this.money(getCart.total)"
						data-pp-currency="USD"
						data-pp-placement="cart"
						data-pp-style-layout="text"
						data-pp-style-logo-type="alternative"
						data-pp-style-logo-position="left"
						data-pp-style-text-align="center"
						data-pp-style-text-size="16"
						data-pp-style-ratio="1x1"
					></div>
					<hr />
					<!-- VIEW/CHECKOUT -->
					<div class="options">
						<a
							href="/cart"
							class="btn btn-md btn-primary"
						>
							<i class="fa fa-fw fa-shopping-cart fa-lg"></i> View Cart
						</a>
						<a
							href="/checkout"
							class="btn btn-md btn-default"
							@click="proceedToCheckout()"
						>
							<i class="fa fa-fw fa-lock fa-lg"></i> Checkout
						</a>
					</div>
				</div>
			</div>
			<div
				class="text-center"
				v-else
			>
				<h3>Your cart is empty.</h3>
			</div>
		</aside>
	</div>
</template>

<script>
import MenuButton from './MenuButton.vue';
import Money from '../../mixins/money.js';

import Cookies from 'js-cookie';
import CoreDepositPopover from '../widgets/CoreDepositPopover.vue';

export default {
	name: 'cart-menu',
	mixins: [Money],
	components: { MenuButton, CoreDepositPopover },

	props: {
		open: { type: Boolean, default: false },
		isMobile: { type: Boolean, default: false },
		stuck: { type: Boolean, default: false },
		cart: { default: () => {} },
		site: { type: String, default: 'pz' },
		additionalMinShippingDays: { type: Number, default: 5 },
		additionalMaxShippingDays: { type: Number, default: 6 },
		holiday: { type: Boolean },
	},

	data() {
		return {
			shortScreen: false,
		};
	},

	computed: {
		siteCdn() {
			switch (this.site) {
				case 'pz':
					return 'https://cdn.partzilla.com/';
				case 'bn':
					return 'https://cdn.boats.net/';
				case 'fd':
					return 'https://cdn.firedog.com/';
			}
		},
		toGoDisplay() {
			if (this.cart.total > 149) return 'You have FREE Shipping!';

			return '$' + this.remaining + ' away from FREE shipping';
		},
		remaining() {
			return this.money(149 - this.cart.total);
		},
		progressBar() {
			return {
				width: Math.floor((this.cart.total / 149) * 100) + '%',
			};
		},
		getCart() {
			if (this.cart !== null) return this.cart;

			return this.cart;
		},
		shipsInPopoverTitle() {
			return '<i class="glyphicon glyphicon-time text-default"></i>';
		},
		inStockPopoverTitle() {
			return '<i class="glyphicon glyphicon-ok-circle text-success"></i> In Stock';
		},
	},

	methods: {
		closeMenu() {
			if (this.open) this.$emit('toggle-cart');
		},
		generatePartUrl(item) {
			return '/product/' + item.vendor_name_key + '/' + item.sku + '?ref=' + item.ref_key;
		},
		proceedToCheckout() {
			if (localStorage.getItem('paypalExpress')) {
				localStorage.removeItem('paypalExpress');
				if (localStorage.getItem('shippingAddress')) {
					localStorage.removeItem('shippingAddress');
				}
				if (localStorage.getItem('billingAddress')) {
					localStorage.removeItem('billingAddress');
				}
			}
			this.gaEcommerceBeginCheckout();
		},
		gaEcommerceBeginCheckout() {
			window.dataLayer.push({ ecommerce: null });

			let items = [];

			if (this.getCart.items.length > 0) {
				this.getCart.items.forEach((p) => {
					items.push({
						item_name: p.name,
						item_id: p.sku,
						price: parseFloat(p.price_display),
						quantity: p.qty,
						item_list_id: Cookies.get('cart_id'),
					});
				});
			}
			window.dataLayer.push({
				event: this.cda === 1 ? 'begin_checkout_cda' : 'begin_checkout',
				ecommerce: {
					items: items,
				},
			});
		},
		getAvailabilityDates(item) {
			let date = Date.now();
			let min = this.getAdditionalMinShippingDays(item);
			let max = this.getAdditionalMaxShippingDays(item);
			let minDate = new Date(date + min * 24 * 60 * 60 * 1000);
			let maxDate = new Date(date + max * 24 * 60 * 60 * 1000);
			let options = {
				month: 'short',
				day: 'numeric',
			};

			if (minDate.getMonth() === maxDate.getMonth()) {
				return minDate.toLocaleDateString('en-US', options) + ' - ' + maxDate.getDate();
			} else {
				return (
					minDate.toLocaleDateString('en-US', options) + ' - ' + maxDate.toLocaleDateString('en-US', options)
				);
			}
		},
		getAdditionalMinShippingDays(product) {
			let productMinShippingDays = parseInt(product.stock_day_min) || 2;
			let minShippingDays = this.holiday ? this.additionalMinShippingDays : 0;

			return productMinShippingDays + minShippingDays;
		},
		getAdditionalMaxShippingDays(product) {
			let productMaxShippingDays = parseInt(product.stock_day_max) || 3;
			let maxShippingDays = this.holiday ? this.additionalMaxShippingDays : 0;

			return productMaxShippingDays + maxShippingDays;
		},
	},

	mounted() {
		const height = window.innerHeight;

		if (height <= 720) this.shortScreen = true;

		$('[data-toggle="popover"]').popover();
	},
};
</script>

<style lang="scss" scoped>
img {
	height: 50px;
	max-width: 50px;
}
aside.cart-menu {
	.cart-bottom {
		#paypal-button {
			height: auto !important;
			margin-top: 1rem;
			width: 100% !important;
		}
		hr {
			margin: 1rem 0;
			border-top: 1px solid #e4e4e4;
		}
	}
}

.in-stock {
	color: #29c30f;
	color: color(display-p3 0.160784 0.764706 0.058824);
	font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
		'Open Sans', 'Helvetica Neue', sans-serif;
	font-optical-sizing: auto;
	font-style: normal;
	font-weight: 600;
	i {
		color: #29c30f;
		color: color(display-p3 0.160784 0.764706 0.058824);
	}
}

div.or {
	margin: 0 0 0.75rem 0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: darken(#e4e4e4, 15%);
	> :not([hidden]) ~ :not([hidden]) {
		margin-left: calc(1rem * calc(1 - 0));
		margin-right: calc(1rem * 0);
	}
	div.or-line {
		flex: 1;
		height: 1px;
		border-bottom: 1px solid darken(#e4e4e4, 15%);
	}
}

div.cart-shade {
	position: absolute;
	height: 100vh;
	width: 100%;
	background-color: rgba(0, 0, 0, 0);
	pointer-events: none;

	transition: background-color 0.1s ease-in-out;

	z-index: 99;

	&.open {
		background-color: rgba(0, 0, 0, 0.45);
		pointer-events: all;
	}

	div.fade-top {
		position: absolute;
		width: 100%;
		height: 32px;
		background: linear-gradient(180deg, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);
	}

	div.fade-bottom {
		position: sticky;
		bottom: 0;
		width: 100%;
		height: 32px;
		background: linear-gradient(0deg, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);
	}

	aside.cart-menu {
		z-index: 101;

		position: absolute;
		height: 100vh;
		background: white;

		width: 380px;
		max-width: 380px;

		display: none;

		right: 0;

		div.cart-top {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			padding: 0;
			color: black;

			background-color: white;
			width: 100%;

			h2 {
				font-weight: 200;
				font-size: 22px;
			}

			button.close-button {
				display: flex;
				align-items: center;
				justify-self: flex-end;
				padding: 0.5em;

				margin: 3px;

				border: none;
				outline: 0;
				background-color: transparent;

				&:hover {
					background: rgba(255, 255, 255, 0.3);
				}
			}
		}

		&.open {
			display: block;
		}

		div.menu-container {
			height: 75%;

			padding: 1em;
			padding-bottom: 0;

			display: flex;
			flex-direction: column;
			justify-content: space-between;

			&.stuck {
				height: 83%;
			}

			&.short {
				height: 72%;

				&.stuck {
					height: 80%;
				}
			}

			div.cart-list {
				overflow-y: scroll;

				div.cart-title {
					color: black;
					font-size: 1.7rem;
					font-weight: bold;
					text-transform: uppercase;
				}

				div.cart-items {
					display: flex;
					flex-direction: column;
					width: 100%;

					&.short {
						// max-height: 50%;
					}

					div.cart-item {
						display: flex;
						align-items: center;
						gap: 1.5rem;
						padding: 0.5em;
						width: 100%;

						div.item-thumb {
							max-width: 64px !important;
							width: 64px !important;
							position: relative;
							display: flex;
							justify-content: center;
							align-items: center;
							figcaption {
								align-items: center;
								background-color: #999;
								background-color: color(display-p3 0.6 0.6 0.6);
								border-radius: 99999px;
								display: flex;
								height: 20px;
								justify-content: center;
								margin: 0;
								padding: calc(3 / var(--default-font-size) * 1rem);
								position: absolute;
								right: -3px;
								top: -7px;
								width: 20px;
								p {
									font-size: 1.2rem;
									// using !important for overwriting bootstrap
									margin: 0 !important;
									color: white;
									color: color(display-p3 1 1 1);
								}
							}
						}

						div.item-info {
							padding: 6px;
							width: 100%;

							div.item-name {
								align-items: center;
								display: flex;
								text-transform: uppercase;
								font-weight: 700;
								justify-content: space-between;
								gap: 1rem;
								a,
								span {
									color: black;
									text-wrap: balance;
								}
								small {
									color: #c3161c;
									font-weight: 600;
									text-align: right;
									&:hover {
										cursor: pointer;
									}
								}
							}
						}

						div.item-data {
							display: flex;
							justify-content: space-between;
						}
					}
				}
			}

			div.cart-bottom {
				position: sticky;
				bottom: 0;
				background-color: white;

				div.shipping-progress.freeShoppingProgressContainer {
					padding: 1em;
					margin-top: 1em;

					border-top: 1px solid #e4e4e4;

					font-size: 1.2rem;

					.progress {
						width: 100%;
						border: solid 1px #fff;
						border-radius: 15px;
						margin: 0;
						background-color: #ccc;
					}

					div.bar {
						display: flex;
						justify-content: center;
						align-items: center;

						span {
							display: block;
							padding: 5px;
						}
					}
				}

				div.subtotal {
					display: flex;
					align-items: center;
					justify-content: space-between;

					padding: 1rem 0 0 0;

					border-top: 1px solid #e4e4e4;

					font-size: 2rem;

					span.subtotal-label {
						font-weight: 200;
					}

					span.subtotal-value {
						font-weight: 700;
					}
				}

				div.options {
					display: flex;
					justify-content: space-between;

					a {
						display: block;
					}
				}
			}
		}
	}

	div.outside {
		width: 100%;
		height: 100%;
		position: absolute;
		pointer-events: none;

		&.open {
			pointer-events: all;
		}
	}
}

.core-item {
	padding-left: 4rem !important;
}

@media (max-width: 991px) {
	div.cart-shade {
		top: 0;
		position: fixed;

		aside.cart-menu {
			&.open {
				width: 100%;
				max-width: 100%;
			}

			div.cart-top {
				justify-content: space-between;

				padding: 0.5em;
				background-color: #e3e3e3;
			}

			div.menu-container {
				height: 90%;
			}
		}
	}
}
</style>
