<template>
	<div>
		<!-- MODAL -->
		<transition
			name="modal"
			mode="out-in"
		>
			<div
				v-if="open"
				class="garage-popup"
				aria-labelledby="garageModalHeading"
			>
				<div
					class="menu-shade"
					:class="{ open: open }"
					aria-hidden="true"
					@click="close"
				></div>
				<div class="popup-body">
					<!-- TOP -->
					<div class="popup-top">
						<h2
							class="hidden-xs"
							id="garageModalHeading"
						>
							<i
								class="fa-sharp fa-solid fa-wrench"
								aria-hidden="true"
							></i>
							My Garage
						</h2>
						<CloseButton
							:has-history="panelHistory.length > 0"
							@close="close"
							@go-back="goBack"
						/>
					</div>

					<!-- CONTENT -->
					<div class="popup-content">
						<Tabs
							class="hidden-xs"
							:active="active"
						>
							<TabsItem
								v-for="(tab, index) in tabs"
								:key="`tab_item_${index}`"
								:class="{ active: active === index }"
							>
								<TabsButton
									:id="`${tab.id}_tab`"
									:class="{ active: active === index }"
									@click.native="active = index"
								>
									{{ tab.title }}
								</TabsButton>
							</TabsItem>
							<TabsItem
								v-if="!isAuthenticated"
								class="hidden-xs"
							>
								<a
									:href="`/account/login?reurl=${loginReURL}`"
									@click.prevent="captureLogin"
								>
									<i
										class="fa fa-user-circle"
										aria-hidden="true"
									></i>
									Sign in to see saved rides
								</a>
							</TabsItem>
						</Tabs>
						<TabsContent class="hidden-xs">
							<TabsContentItem
								v-for="(tab, index) in tabs"
								:key="`tab_content_item_${index}`"
								:class="{ active: active === index }"
								:id="`${tab.id}_tab`"
							>
								<component
									:is="tab.component"
									v-bind="tab.props"
									:active="active === index"
									v-on:[tab.event]="tab.handler"
								></component>
							</TabsContentItem>
						</TabsContent>
						<Panels class="visible-xs">
							<Panel>
								<PanelHeader>
									<template
										v-if="activePanelData.id !== 'stored-rides'"
										#user
									>
										<a
											v-if="!isAuthenticated"
											:href="`/account/login?reurl=${loginReURL}`"
											@click.prevent="captureLogin"
										>
											<i
												class="fa fa-user-circle"
												aria-hidden="true"
											></i>
											Sign in to see saved rides
										</a>
										<button
											v-else-if="hasStoredRide || localGarage.length > 0"
											class="btn-link"
											@click.prevent="selectGaragePanelById('stored-rides')"
										>
											<i
												class="fa fa-user-circle"
												aria-hidden="true"
											></i>
											See saved rides
										</button>
									</template>
									<PanelTitle :title="activePanelData.title" />
									<PanelDescription :description="activePanelData.description" />
								</PanelHeader>
								<component
									:id="activePanelData.id"
									:is="activePanelData.component"
									:enable-automotive="enableAutomotive"
									v-bind.sync="activePanelData.props"
									v-on:[activePanelData.event]="activePanelData.handler"
									@update:active-panel="selectGaragePanelById"
									@go-back="goBack"
									@set:ride="setRide"
									@remove:ride="removeRide"
									@remove:rides="removeMultipleRides"
									@clear:ride="clearRide"
								></component>
							</Panel>
						</Panels>
					</div>

					<!-- GARAGE -->
					<div
						v-if="hasStoredRide || localGarage.length > 0"
						class="garage hidden-xs"
					>
						<div class="current">
							<h4>Currently Shopping For:</h4>
							<StoredRideButton
								v-if="hasStoredRide"
								:class="{
									selected:
										isRideSelected(storedRide) &&
										(!isRideLoading(storedRide) || !isRideBeingCleared(storedRide)),
									loading: isRideLoading(storedRide) || isRideBeingCleared(storedRide),
								}"
								@set:ride="shopForCurrent('Stored Ride Button')"
							>
								<h3>
									{{ storedRideName }}
									<i
										v-if="
											isRideSelected(storedRide) &&
											!isRideLoading(storedRide) &&
											!isRideBeingCleared(storedRide)
										"
										class="fa fa-circle-check selected"
										aria-hidden="true"
									></i>
								</h3>
								<template #remove>
									<i
										:class="[
											'fa',
											{
												'fa-spinner fa-spin':
													isRideLoading(storedRide) || isRideBeingCleared(storedRide),
											},
										]"
									></i>
								</template>
							</StoredRideButton>
							<StoredRideButton
								v-show="
									localGarageFiltered.length > 0 &&
									!hasStoredRide &&
									!isRideLoading(storedRide) &&
									!isRideBeingCleared(storedRide)
								"
								class="template loading"
								:disabled="true"
							>
								<h3>
									<em>{{ emptyStoredRideText }}</em>
								</h3>
							</StoredRideButton>

							<div
								v-show="hasStoredRide"
								class="options"
							>
								<button
									class="btn-link btn-xs"
									@click="shopForCurrent('Shop For Current Button')"
								>
									<i class="fa fa-fw fa-wrench"></i> Shop OE Parts
								</button>
								<button
									class="btn-link btn-xs"
									@click="clearRide"
								>
									<i class="fa fa-fw fa-times-circle"></i> Shop Without
									{{ capWords(vehicleType) }}
								</button>
							</div>
						</div>

						<SavedRides
							:build-ride-name="buildRideName"
							:site="site"
							:local-garage.sync="localGarage"
							:local-garage-filtered.sync="localGarageFiltered"
							:is-ride-being-cleared="isRideBeingCleared"
							:is-ride-loading="isRideLoading"
							:clearing="clearing"
							:clear-list="clearList"
							:get-garage-id="getGarageId"
							@set:ride="setRide"
							@remove:ride="removeRide"
							@remove:rides="removeMultipleRides"
							@reset:clearing="clearing = false"
						></SavedRides>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import axios from 'axios';
import garage from '@/mixins/garage.js';
import strings from '@/mixins/strings.js';
import Cookies from 'js-cookie';
import Tracking from '@/mixins/event-tracking';

export default {
	name: 'garage-menu',
	mixins: [garage, strings, Tracking],
	components: {
		Tabs: () => import('@/components/product-display/information/tabs/Index.vue'),
		TabsItem: () => import('@/components/product-display/information/tabs/Item.vue'),
		TabsButton: () => import('@/components/product-display/information/tabs/Button.vue'),
		TabsContent: () => import('@/components/product-display/information/tabs/content/Index.vue'),
		TabsContentItem: () => import('@/components/product-display/information/tabs/content/Item.vue'),
		Panels: () => import('@/components/garage/panels/Index.vue'),
		Panel: () => import('@/components/garage/panels/panel/Index.vue'),
		PanelHeader: () => import('@/components/garage/panels/panel/header/Index.vue'),
		PanelTitle: () => import('@/components/garage/panels/panel/header/Title.vue'),
		PanelDescription: () => import('@/components/garage/panels/panel/header/Description.vue'),
		PanelBody: () => import('@/components/garage/panels/panel/body/Index.vue'),
		FitmentBar: () => import('@/components/fitment-bar/FitmentBar.vue'),
		LicensePlateLookup: () => import('@/components/fitment-bar/LicensePlateLookup.vue'),
		VinLookup: () => import('@/components/fitment-bar/VinLookup.vue'),
		NewRide: () => import('@/components/garage/panels/NewRide.vue'),
		StoredRides: () => import('@/components/garage/panels/StoredRides.vue'),
		CloseButton: () => import('@/components/garage/CloseButton.vue'),
		StoredRideButton: () => import('@/components/garage/StoredRideButton.vue'),
		SavedRides: () => import('@/components/garage/SavedRides.vue'),
	},
	props: {
		open: {
			type: Boolean,
			default: false,
		},
		site: {
			type: String,
			default: 'pz',
		},
		isAuthenticated: {
			type: Boolean,
			default: false,
		},
		redirect: {
			type: Boolean,
		},
		machineApiUrl: {
			type: String,
		},
		machineToken: {
			type: String,
		},
		axiosBaseUrl: {
			type: String,
		},
		solrToken: {
			type: String,
		},
		autoSearchToken: {
			type: String,
		},
		enableAutomotive: { type: Boolean },
	},
	data() {
		return {
			clearing: false,
			clearList: [],
			updating: false,
			active: 0,
			activePanel: null,
			panelHistory: [],
			vinLookupErrorMessage: '',
			licensePlateLookupErrorMessage: '',
		};
	},
	provide() {
		return {
			site: this.site,
		};
	},
	created() {
		this.checkForStoredRide();

		if (this.hasStoredRide) {
			this.getStoredRide();
		}

		if (this.isAuthenticated) {
			this.getBackendGarage();
		} else {
			this.getLocalGarage();
		}
	},
	watch: {
		localGarage: {
			handler(newValue, oldValue) {
				if (newValue.length > 0) {
					this.selectGaragePanelById('stored-rides');
				} else {
					this.selectGaragePanelById('new-ride');
				}
			},
			deep: true,
			immediate: true,
		},
		active: {
			handler(newValue, oldValue) {
				// for desktop tabs
				if (newValue !== oldValue) {
					this.resetErrorMessages();
				}
			},
		},
	},
	computed: {
		vehicleType() {
			if (this.site === 'pz') return 'ride';
			if (this.site === 'bn') return 'engine';
		},
		/**
		 * Create array for tabs to loop over
		 */
		tabs() {
			let tabBank = [];
			tabBank.push(this.makeModelData);
			if (this.enableAutomotive) {
				tabBank.push(this.licensePlateLookupData);
				tabBank.push(this.vinLookupData);
			}

			return tabBank;
		},
		makeModelData() {
			return {
				id: 'make-model',
				title: 'Make / Model',
				description: 'Provide your vehicle details below to get an exact fit for your ride:',
				props: {
					site: this.site,
					machineToken: this.solrToken,
					axiosBaseUrl: this.axiosBaseUrl,
					isAuthenticated: this.isAuthenticated,
					clearing: this.clearing,
					updating: this.updating,
					enableAutomotive: this.enableAutomotive,
				},
				event: 'selected',
				handler: this.selectedFitment,
				component: 'FitmentBar',
			};
		},
		licensePlateLookupData() {
			return {
				id: 'license-plate',
				title: 'License Plate',
				description: 'Provide your license plate details below to get an exact fit for your ride:',
				props: {
					site: this.site,
					machineToken: this.machineToken,
					axiosBaseUrl: this.axiosBaseUrl,
					isAuthenticated: this.isAuthenticated,
					clearing: this.clearing,
					updating: this.updating,
					error: this.licensePlateLookupErrorMessage,
				},
				event: 'search:license-plate',
				handler: this.searchLicensePlate,
				component: 'LicensePlateLookup',
			};
		},
		vinLookupData() {
			return {
				id: 'vin-lookup',
				title: 'VIN Lookup',
				description: 'Enter your 17 digit VIN to get an exact fit for your ride:',
				props: {
					site: this.site,
					machineToken: this.machineToken,
					axiosBaseUrl: this.axiosBaseUrl,
					isAuthenticated: this.isAuthenticated,
					clearing: this.clearing,
					updating: this.updating,
					error: this.vinLookupErrorMessage,
				},
				event: 'search:vin',
				handler: this.searchVin,
				component: 'VinLookup',
			};
		},
		createStoredRidePanel() {
			return {
				id: 'stored-rides',
				title: 'My Garage',
				description: 'To get an exact fit, choose a previously selected ride below. Or add a new ride:',
				props: {
					loading: this.updating,
					clearing: this.clearing,
					clearList: this.clearList,
					site: this.site,
					buildRideName: this.buildRideName,
					localGarage: this.localGarage,
					localGarageFiltered: this.localGarageFiltered,
					getGarageId: this.getGarageId,
					isRideLoading: this.isRideLoading,
					isRideBeingCleared: this.isRideBeingCleared,
					isRideSelected: this.isRideSelected,
				},
				event: '',
				handler: '',
				component: 'StoredRides',
			};
		},
		createNewRidePanel() {
			const buttons = [
				{
					id: 'make-model',
					title: 'Make / Model',
					description: 'Search by Make, Year, Type & Model',
					icon: 'fa fa-search',
				},
			];

			if (this.enableAutomotive) {
				buttons.push(
					{
						id: 'license-plate',
						title: 'License Plate',
						description: 'Search by U.S. State License Plate',
						icon: 'fa-sharp fa-solid fa-input-text',
					},
					{
						id: 'vin-lookup',
						title: 'VIN Lookup',
						description: 'Search by Vehicle Identification Number',
						icon: 'fa-sharp fa-solid fa-rectangle-barcode',
					}
				);
			}

			return {
				id: 'new-ride',
				title: 'My Garage',
				description: 'Select an option below to get an exact fit for your ride:',
				props: {
					buttons: [...buttons],
				},
				event: '',
				handler: '',
				component: 'NewRide',
			};
		},
		panels() {
			const panels = [];

			if ((this.hasStoredRide || this.localGarage.length > 0) && this.activePanel === 'stored-rides') {
				panels.push(this.createStoredRidePanel);
			} else {
				panels.push(this.createNewRidePanel);
			}
			panels.push(this.makeModelData);

			if (this.enableAutomotive) {
				panels.push(this.licensePlateLookupData);
				panels.push(this.vinLookupData);
			}

			return panels;
		},
		activePanelData() {
			return this.panels.find((entry) => entry.id == this.activePanel);
		},
		loginReURL() {
			return btoa(location.href);
		},
		emptyStoredRideText() {
			if (this.localGarageFiltered.length >= 1) {
				return 'Choose a previously saved ride';
			}
			return 'Choose a new ride';
		},
	},
	methods: {
		captureLogin() {
			this.trackEvent('garageMenuLogin', {
				eventCategory: 'Garage Menu',
				eventLabel: 'Garage Menu Login',
			});
			window.location.href = `/account/login?reurl=${this.loginReURL}`;
		},
		/**
		 * This function closes the modal & removes classes from the HTML element.
		 */
		close() {
			if (this.open) {
				this.trackEvent('garageMenuClose', {
					eventCategory: 'Garage Menu',
					eventLabel: 'Garage Menu Close',
				});
				this.resetPanels();
				this.$emit('toggle-garage');
				document.documentElement.classList.remove('menuOpen');
			}
		},
		resetPanels() {
			if (this.hasStoredRide || this.localGarage.length > 0) {
				this.selectGaragePanelById('stored-rides');
			} else {
				this.selectGaragePanelById('new-ride');
			}
			this.resetErrorMessages();
			this.active = 0;
			this.panelHistory = [];
		},
		resetErrorMessages() {
			this.vinLookupErrorMessage = '';
			this.licensePlateLookupErrorMessage = '';
		},
		goBack() {
			this.trackEvent('garageMenuGoBack', {
				eventCategory: 'Garage Menu',
				eventLabel: 'Garage Menu Go Back (Mobile)',
			});
			if (this.panelHistory.length > 0) {
				this.activePanel = this.panelHistory.pop();
			} else {
				this.close();
			}
			this.resetErrorMessages();
		},
		/**
		 * This function sets the fitment the user as selected,
		 */
		selectedFitment() {
			this.getStoredRide();
			this.$emit('new-ride');

			this.$nextTick(() => {
				this.getStoredRide();
				this.checkForStoredRide();
				this.getLocalGarage();
				this.checkRedirect();
			});

			this.$emit('toggle-garage');
		},
		setRide(ride) {
			this.trackEvent('garageMenuSetRide', {
				eventCategory: 'Garage Menu',
				eventLabel: 'Garage Menu Set Ride',
				rideId: this.garageFitmentId,
				rideName: this.storedRideName,
			});

			this.loading = true;

			this.setStoredRide(ride);

			this.updateSelected(ride);

			this.$emit('new-ride');

			// this.$emit("toggle-garage");
		},
		checkRedirect() {
			// if redirect is enabled
			if (this.redirect) {
				// is the user already on a catalog product page?
				const pageTypes = ['catalog-product', 'product', 'collection'];
				if (pageTypes.some((type) => location.pathname.includes(type))) {
					const urlParams = new URLSearchParams(location.search);
					const preserveKeys = ['ref', 'use_fitment'];

					// remove all parameters except the ones we want preserved
					for (const key of [...urlParams.keys()]) {
						if (!preserveKeys.includes(key)) {
							urlParams.delete(key);
						}
					}

					// ensure `use_fitment` exists in the URL parameters and set the cookie
					// if (!urlParams.has('use_fitment')) {
					// 	urlParams.set('use_fitment', 1);
					// 	Cookies.set('use_fitment', 1);
					// }
					// update the browser history state with the new parameters
					const updatedUrl = `${location.pathname}?${urlParams}`;
					history.pushState({ use_fitment: urlParams.get('use_fitment') }, '', updatedUrl);

					// reload the page to apply changes
					location.reload();
					return;
				}
				// relocate to catalog page specific for ride
				else {
					this.shopForCurrent('New Ride Selected');
				}
			}
			// if redirect is disabled
			else {
				if (location.pathname.includes('search')) {
					const url = new URLSearchParams(location.search);
					if (!url.has('use_fitment')) {
						url.set('use_fitment', 1);
						Cookies.set('use_fitment', 1);
					}
					const stateObject = { use_fitment: 1 };
					const urlString = url.toString();
					history.pushState(stateObject, '', `${location.pathname}?${urlString}`);
				}
				location.reload();
			}
		},
		updateSelected(ride) {
			/**
			 * instead of using the computed property: this.garageFitmentId
			 * from the garage mixin,
			 * use the passed ride
			 */
			const id = this.getRideFitmentId(ride);
			axios
				.post(`/garage/update/${id}`)
				.then((results) => {
					this.checkForStoredRide();

					if (this.hasStoredRide) {
						this.getStoredRide();
					}
				})
				.catch((error) => {
					console.error('There was an error with the update garage request: ', error.message);
				})
				.finally(() => {
					// redirect
					this.checkRedirect();
				});
		},
		async clearRide() {
			this.clearing = true;
			this.trackEvent('garageMenuClearRide', {
				eventCategory: 'Garage Menu',
				eventLabel: 'Garage Menu Clear Ride',
				rideId: this.garageFitmentId,
				rideName: this.storedRideName,
			});
			await axios.get('/garage/clear').then(() => {
				this.removeFitmentParam();
				this.clearStoredRide();
				window.location.reload();
			});

			this.$emit('new-ride');
			this.close();

			this.$emit('toggle-garage');
		},
		async removeRide(id) {
			this.clearList.push(id);
			this.clearing = true;
			if (this.isAuthenticated) {
				try {
					const url = new URL(`/account/garage/delete/${id}`, location);
					await this.deleteBackendRide(url);
				} catch (error) {
					console.error('There was an error with the delete garage request: ', error.message);
					return;
				}
			}
			await this.removeLocalRide(id);
		},
		async removeMultipleRides(ids) {
			this.clearList = ids;
			this.clearing = true;
			if (this.isAuthenticated) {
				try {
					const url = new URL('/account/garage/deleteMulti', location);
					const body = {
						ids: ids.map((id) =>
							this.getGarageId(this.localGarage.find((ride) => this.getGarageId(ride) == id))
						),
					};
					await this.deleteBackendRide(url, body);
				} catch (error) {
					this.clearing = false;
					console.error('There was an error with the delete garage request: ', error.message);
					return;
				}
			}

			await this.removeLocalRides(ids);
		},
		async deleteBackendRide(url, body = null) {
			const options = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: body ? JSON.stringify(body) : null,
			};
			const request = new Request(url, options);
			const response = await fetch(request);
			if (!response.ok) {
				throw new Error('Sorry, we could not delete the ride from the garage.');
			}

			this.removeFitmentParam();
		},
		async removeLocalRide(id) {
			if (this.hasStoredRide && this.getGarageId(this.storedRide) === id) {
				await this.clearRide();
			}

			const updatedGarage = this.localGarage.reduce((acc, ride) => {
				if (this.getGarageId(ride) !== id) {
					acc.push(ride);
				}
				return acc;
			}, []);

			await this.updateLocalGarage(updatedGarage)
				.then(async () => {
					if (this.isAuthenticated) {
						await this.getBackendGarage();
					} else {
						this.getLocalGarage();
					}
				})
				.then(() => {
					this.clearing = false;
				});
		},
		async removeLocalRides(ids) {
			if (this.hasStoredRide && ids.includes(this.getGarageId(this.storedRide))) {
				await this.clearRide();
			}

			const updatedGarage = this.localGarage.reduce((acc, ride) => {
				if (!ids.includes(this.getGarageId(ride))) {
					acc.push(ride);
				}
				return acc;
			}, []);

			await this.updateLocalGarage(updatedGarage)
				.then(async () => {
					if (this.isAuthenticated) {
						await this.getBackendGarage();
					} else {
						this.getLocalGarage();
					}
				})
				.then(() => {
					this.clearing = false;
				})
				.then(() => {
					this.clearList = [];
				});
		},
		buildRideName(ride) {
			const { name, year, make, model } = ride;
			if (name && name !== null) {
				return name;
			}

			return `${year} ${make} ${model}`;
		},
		shopForCurrent(eventSource) {
			if (this.hasStoredRide) {
				if (this.garageFitmentId) {
					this.trackEvent('garageMenuShopForCurrent', {
						eventCategory: 'Garage Menu',
						eventLabel: 'Garage Menu Shop For Current',
						rideId: this.garageFitmentId,
						rideName: this.storedRideName,
						eventSource,
					});
					const url = new URL(
						`/catalog/v1/${this.garageFitmentId}/${this.storedRideName
							.replace(/[-]/g, '')
							.replace(/\s/g, '-')}`,
						location
					);
					url.searchParams.set('use_fitment', 1);
					url.searchParams.set('ui', 'typeahead');
					Cookies.set('use_fitment', 1);
					window.location = url.href;
				}
				return;
			}

			// alert('Please choose a ride to shop for ride!');
		},
		searchFromQuery() {
			let search = '/search?q=+fitment:' + this.garageFitmentId;

			window.location = search;
		},
		selectGaragePanelById(id) {
			this.trackEvent('garageMenuSelectPanel', {
				eventCategory: 'Garage Menu',
				eventLabel: 'Garage Menu Select Panel',
				panelId: id,
			});
			if (this.activePanel !== null && this.activePanel !== id) {
				this.panelHistory.push(this.activePanel);
			}
			this.activePanel = id;
		},
		async machineApiBase(body, endpoint) {
			const headers = new Headers({
				Authorization: `Bearer ${atob(this.autoSearchToken)}`,
				'Content-Type': 'application/json',
			});
			const options = {
				method: 'POST',
				headers,
				body: JSON.stringify(body),
			};
			const base = this.machineApiUrl.startsWith('https://')
				? this.machineApiUrl
				: `https://${this.machineApiUrl}`;
			const url = new URL(endpoint, base);
			const request = new Request(url, options);
			try {
				const response = await fetch(request);
				if (!response.ok) {
					throw new Error('Sorry, we could not find your vehicle information.');
				}
				return response.json();
			} catch (error) {
				throw error;
			}
		},
		async searchMachineApi(body, endpoint) {
			this.loading = true;

			try {
				const ride = await this.machineApiBase(body, endpoint);

				await this.validateThenAddRide(ride);
			} catch (error) {
				throw error;
			} finally {
				this.loading = false;
			}
		},
		async searchVin(vin, endpoint = '/api/vinSearch') {
			try {
				await this.searchMachineApi(vin, endpoint);
			} catch (error) {
				$('.vin-submit-btn').button('reset');
				this.vinLookupErrorMessage = error
					? error.message
					: "We're sorry, we ran into an issue finding this VIN. Please update the VIN and try again.";
			}
		},
		async searchLicensePlate(plate, endpoint = '/api/plateSearch') {
			try {
				await this.searchMachineApi(plate, endpoint);
			} catch (error) {
				$('.license-plate-submit-btn').button('reset');
				this.licensePlateLookupErrorMessage = error
					? error.message
					: "We're sorry, we ran into an issue finding this US License Plate. Please update the US License Plate and try again.";
			}
		},
		async validateThenAddRide(ride) {
			try {
				await this.checkRideInLocalGarage(ride);
			} catch (error) {
				throw error;
				return;
			}

			await this.addToGarage(ride)
				.then(() => {
					this.setStoredRide(ride);
				})
				.then(() => {
					this.addToLocalGarage();
				})
				.then(() => {
					this.addingToGarage = false;
					this.addedToGarage = true;
				})
				.then(() => {
					this.$emit('new-ride');
				})
				.then(() => {
					this.checkRedirect();
				})
				.catch((error) => {
					throw error;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.manage-garage-button {
	font-weight: 700;
	margin-top: 1em;
	text-transform: uppercase;
}
.garage-popup {
	align-items: center;
	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	min-height: 100vh;
	overflow-y: scroll;
	position: fixed;
	right: 0;
	transition: all 500ms ease-in-out;
	top: 0;
	z-index: 10;

	.menu-shade {
		&.open {
			background-color: rgba(0, 0, 0, 0.45);
			background-color: color(display-p3 0 0 0 / 0.45);
			backdrop-filter: blur(calc(3 / var(--default-font-size, 14) * 1rem));
			overflow-y: scroll;
			pointer-events: all;
			position: fixed;
		}
	}

	.popup-body {
		background-color: white;
		background-color: color(display-p3 1 1 1);
		box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
		display: flex;
		flex-direction: column;
		gap: calc(36 / var(--default-font-size, 14) * 1rem);
		position: absolute;
		transition: all 500ms ease-in-out;
		top: 5%;
		width: max(768px, 60vw);
		z-index: 200;

		.popup-top {
			align-items: center;
			align-self: stretch;
			border-bottom: 2px solid #eee;
			border-bottom: 2px solid color(display-p3 0.9333 0.9333 0.9333);
			display: flex;
			justify-content: space-between;
			margin: 0 calc(32 / var(--default-font-size, 14) * 1rem);
			padding: calc(16 / var(--default-font-size, 14) * 1rem) 0;

			h2 {
				align-items: center;
				color: #222;
				color: color(display-p3 0.1333 0.1333 0.1333);
				display: flex;
				font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
					Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
				font-size: calc(20 / var(--default-font-size, 14) * 1rem);
				font-style: normal;
				font-weight: 700;
				gap: 1rem;
				justify-content: flex-start;
				line-height: normal;
				i {
					font-size: calc(22 / var(--default-font-size, 14) * 1rem);
					font-style: normal;
					line-height: normal;
				}
			}
		}

		.popup-content {
			display: flex;
			flex-direction: column;
			#tabs {
				border: none;
				display: flex;
				padding: 0 calc(32 / var(--default-font-size, 14) * 1rem);
				ul {
					gap: 2rem;
					li {
						&:last-of-type {
							&:not(:has(> a.tab-button)) {
								align-self: stretch;
								flex: 1 0 auto;
								text-align: right;
							}
						}
						.tab-button {
							font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
								Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
							text-transform: none;
						}
						&:nth-child(n + 3) {
							border-right: none;
							padding-right: 0;
						}
						&:only-child {
							align-self: start;
							flex: 0 0 auto;
							text-align: left;
						}
					}
				}
			}
			#content.tab-content {
				align-items: flex-start;
				align-self: stretch;
				display: flex;
				flex-direction: column;
				gap: calc(16 / var(--default-font-size, 14) * 1rem);
				padding: calc(32 / var(--default-font-size, 14) * 1rem);
				> :not([hidden]) {
					align-self: stretch;
				}
			}
		}

		.garage {
			align-items: flex-start;
			display: flex;
			gap: calc(32 / var(--default-font-size, 14) * 1rem);
			justify-content: center;
			padding: 0 calc(32 / var(--default-font-size, 14) * 1rem);
			position: relative;
			transition: all 500ms ease-in-out;

			& > :not([hidden]),
			& ~ :not([hidden]) {
				align-items: flex-start;
				align-self: stretch;
				display: flex;
				flex: 0 1 50%;
				flex-direction: column;
				gap: calc(24 / var(--default-font-size, 14) * 1rem);
				padding: 0 0 calc(32 / var(--default-font-size, 14) * 1rem);
			}

			h4 {
				color: #000;
				color: color(display-p3 0 0 0);
				font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
					Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
				font-size: calc(20 / var(--default-font-size, 14) * 1rem);
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-transform: none;
				margin: 0;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.garage-popup {
		top: unset;
		.popup-body {
			align-items: flex-start;
			align-self: stretch;
			bottom: 0;
			display: flex;
			flex-direction: column;
			gap: calc(10 / var(--default-font-size, 14) * 1rem);
			justify-content: flex-end;
			padding: calc(16 / var(--default-font-size, 14) * 1rem);
			top: unset;
			width: max(328px, 100vw);
			.popup-top {
				border: 0;
				align-items: center;
				align-self: stretch;
				display: flex;
				gap: calc(10 / var(--default-font-size, 14) * 1rem);
				margin: 0;
				padding: calc(8 / var(--default-font-size, 14) * 1rem) calc(16 / var(--default-font-size, 14) * 1rem);
			}
			.popup-content {
				align-items: center;
				align-self: stretch;
				justify-content: center;
				margin: 0;
				.fitment-container {
					align-items: flex-start;
					align-self: stretch;
					display: flex;
					flex-direction: column;
					gap: calc(24 / var(--default-font-size, 14) * 1rem);
					width: 100%;
				}
			}
			.garage {
				flex-direction: column;
				.current {
					flex-basis: unset;
				}

				.garage-list {
					&:only-child {
						margin-left: 0 !important;
					}
				}

				.options {
					flex-basis: unset;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-start;

					button {
						width: 100%;
						border: none !important;
						font-size: 1.4rem;
					}
				}
			}
		}
	}
}
</style>
