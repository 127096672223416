<template>
    <div
        :style="{
            'display': inline ? 'inline' : 'block',
        }"
        class="cursor-pointer core-deposit-popover"
        data-toggle="popover"
        data-html="true"
        data-placement="bottom"
        :data-trigger="trigger"
        data-content="A core deposit is a refundable fee that's added to the price of a part to encourage the return of the old part for remanufacturing. The manufacturer sets the core charge based on the part's value or reusability. If you return the old part, you will be refunded the core charge."
    >
        <slot />
        <i class="fa fa-question-circle auto-popover fa-lg" />
    </div>
</template>

<script>
export default {
    props: {
        trigger: {
            type: String,
            default: 'hover',
        },
        inline: {
            type: Boolean,
            default: false,
        },
    },
	mounted() {
		$('[data-toggle="popover"]').popover();
	},
};
</script>
<style scoped lang="scss">
.auto-popover {
    margin-left: 5px;
    position: relative;
    top: -1px;
}
</style>
