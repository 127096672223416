import { render, staticRenderFns } from "./CoreDepositPopover.vue?vue&type=template&id=3f38b2d0&scoped=true"
import script from "./CoreDepositPopover.vue?vue&type=script&lang=js"
export * from "./CoreDepositPopover.vue?vue&type=script&lang=js"
import style0 from "./CoreDepositPopover.vue?vue&type=style&index=0&id=3f38b2d0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f38b2d0",
  null
  
)

export default component.exports