var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-container",class:_vm.site},[_c('form',{staticClass:"search",class:_vm.site,on:{"submit":function($event){$event.preventDefault();return _vm.submitQuery.apply(null, arguments)}}},[_c('div',{staticClass:"search-form",class:[_vm.site, {
          focused: _vm.focused,
          'has-results': _vm.hasResults,
        }]},[_c('input',{ref:"originalInput",staticClass:"search-input",class:{ 'has-results': _vm.hasResults && _vm.focused },attrs:{"type":"text","placeholder":_vm.placeholderDynamic},domProps:{"value":_vm.query},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();return _vm.changeSelection(-1)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();return _vm.changeSelection(1)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 2)return null;return _vm.useSelection()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.submitQuery(null, 'keydown - enter')}],"focus":_vm.onFocus,"blur":function($event){return _vm.onBlur(false)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.onBlur(true)},"input":(e) => _vm.onChange(e)}}),_c('button',{staticClass:"search-button",class:{
            'has-results': _vm.hasResults && _vm.focused,
            bn: _vm.site === 'bn',
            fd: _vm.site === 'fd',
          },attrs:{"type":"submit"}},[_c('i',{staticClass:"fa fa-fw fa-search fa-lg"})])])]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.focused)?_c('autocomplete',{attrs:{"query":_vm.query,"results":_vm.results,"styled-results":_vm.styledResults,"selected-fitment":_vm.selectedFitment,"selected-fitment-text":_vm.selectedFitmentText,"query-loading":_vm.loading,"auth":_vm.auth,"cda":_vm.cda,"domain":_vm.domain,"addMin":_vm.addMin,"addMax":_vm.addMax,"holiday":_vm.holiday,"site":_vm.site,"search-items":_vm.searchItems,"popular-brands":_vm.popularBrands,"is-mobile":_vm.isMobile,"dc":_vm.dc,"product-pipeline":_vm.productPipeline,"typeahead-pipeline":_vm.typeaheadPipeline,"query-bearer-token":_vm.queryBearerToken},on:{"mouseenter":_vm.mouseOverAutocomplete,"mouseleave":_vm.mouseLeaveAutocomplete,"submitted":function($event){_vm.submitted = true}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }